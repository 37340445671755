import { navigate, PageProps } from 'gatsby';
import { ChannelData } from 'packages/innedit';
import React, { FC, SyntheticEvent } from 'react';

import Channel from '~/components/List/Item/Channel';
import List from '~/containers/Espace/List';
import IconChevronLeft from '~/icons/ChevronLeft';
import IconPlus from '~/icons/Plus';
import params from '~/params/page.json';
import TemplateAdmin from '~/templates/admin';
import requireUser, { UserProps } from '~/utils/requireUser';
import useChannel from '~/utils/useChannel';
import useEspace from '~/utils/useEspace';

const ChannelsRedirects: FC<PageProps & UserProps> = ({
  location,
  user,
  params: { channelId, id: espaceId },
}) => {
  const [espace] = useEspace({ id: espaceId, pathname: location.pathname });
  const [channel, items] = useChannel({
    channelId,
    espaceId,
    admin: true,
    pathname: location.pathname,
  });

  const model = new ChannelData({
    espaceId,
    params,
    orderDirection: 'desc',
    orderField: 'label',
    wheres: {
      parent: channelId,
    },
  });

  const handleCloseOnClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    return navigate(-1);
  };

  return (
    <TemplateAdmin>
      <List
        allowSorting
        itemList={Channel}
        menu={{
          left: [
            {
              icon: IconChevronLeft,
              label: 'Fermer',
              onClick: handleCloseOnClick,
            },
          ],
          right: [
            {
              icon: IconPlus,
              label: 'Ajouter un enfant',
              to: `/admin/espaces/${espaceId}/channels/create?parent=${channelId}&kind=redirect`,
            },
          ],
        }}
        model={model}
        removeAddButton
        search={location.search}
        subMenu={items}
        title={`${espace?.label} ⇾ ${channel?.label}`}
        user={user}
      />
    </TemplateAdmin>
  );
};

export default requireUser(ChannelsRedirects, 'admin');
